import React from "react";

import config from "config";

const ComingSoonPage = () => {
  return (
    <div className="card-body text-center">
      {/* <div className="display-5 text-muted mb-3">
        <b>Coming Soon - Premium Subscription</b>
      </div> */}
      <h1 className="h3 mb-3">
        <b>Coming Soon - Premium Subscription</b>
      </h1>
      <p className="h6 text-muted font-weight-normal mb-3">
        Upgrade to Crewmen Premium features exclusively via in app purchase
        soon.
      </p>
      <a className="btn btn-primary" href={config.CONTINUE_URL}>
        <i className="fe fe-arrow-left mr-2" />
        Back to Home
      </a>
    </div>
  );
};

export default ComingSoonPage;
