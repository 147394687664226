export default {
  NODE_ENV: "development",

  // end points
  BASE_URL: "https://dev-dot-crewmen-d53cb.ts.r.appspot.com/api/v1",
  EP_GET_SUBSCRIBE_PLANS: "/subscription/getSubscriptionPlans",
  EP_GET_SUBSCRIPTION_FOR_MANAGE: "/subscription/getSubscriptionPlansForManage",
  EP_APPLY_PLANS_COUPON: "/subscription/applyPlanCoupon",
  EP_CREATE_SUBSCRIPTION: "/subscription/createPlanSubscription",
  EP_CANCEL_SUBSCRIPTION: "/subscription/cancelPlanSubscription",
  EP_RESUBSCRIBE_SUBSCRIPTION: "/subscription/resubscribePlanSubscriptionPP",
  EP_UPDATE_SUBSCRIPTION: "/subscription/updatePlanSubscriptionPP",

  // URLs
  CONTINUE_URL: "https://crewmen.page.link/PsgF",

  STRIPE_PK:
    "pk_test_51MSwDVJumdJUswCdiV4cqvXW0OtEthmHtVxv6ALebmAEWIRdNaKpv6MwHheP8pmwhzNbIM8PZkekuPHxzXbX2Wse00MJLc0s5Y",
};
