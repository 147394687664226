import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { useDispatch } from "react-redux";

import "./App.css";

import { resetReducerAction } from "stores/actions/userAction";

// layouts
import MainLayout from "layouts/MainLayout";

// pages
import NotFoundPage from "pages/NotFoundPage";
import SubscribePage from "pages/SubscribePage";
import ManageSubscriptionPage from "pages/ManageSubscriptionPage";
import PaymentSuccessPage from "pages/PaymentSuccessPage";
import CancelSuccessPage from "pages/CancelSuccessPage";
import UpdateSuccessPage from "pages/UpdateSuccessPage";
import ComingSoonPage from "pages/ComingSoonPage";

const App = () => {
  // hooks
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      console.log("ALL REDUCERS ARE CLEARED.");
      dispatch(resetReducerAction());
    };
  }, []);

  return (
    <div className="font-montserrat">
      <Routes>
        <Route element={<MainLayout />}>
          {/* coming soon page */}
          <Route path="*" element={<ComingSoonPage />} />

          {/* subscribe page */}
          {/* <Route path="/subscribe" element={<SubscribePage />} /> */}

          {/* manage subscription page */}
          {/* <Route
            path="/manageSubscription"
            element={<ManageSubscriptionPage />}
          /> */}

          {/* payment success page */}
          {/* <Route path="/paymentSuccess" element={<PaymentSuccessPage />} /> */}

          {/* cancel success page */}
          {/* <Route path="/cancelSuccess" element={<CancelSuccessPage />} /> */}

          {/* plan update success page */}
          {/* <Route path="/updateSuccess" element={<UpdateSuccessPage />} /> */}

          {/* not found page */}
          {/* <Route path="*" element={<NotFoundPage />} /> */}
        </Route>
      </Routes>
    </div>
  );
};

export default App;
