import React from "react";

import Logo from "assets/images/logo.png";
import { useSelector } from "react-redux";

const Navbar = (props) => {
  const user = useSelector((states) => states.userReducer.user);

  // variables
  const { profileImageUrl } = props;

  return (
    <div id="header_top" className={`header_top`}>
      <div className="container">
        <div className="hleft">
          {/* logo */}
          <img className="brand-logo header-brand p-0" src={Logo} alt="Logo" />
        </div>

        {/* user profile */}
        {/* <div className="hright">
          {profileImageUrl ? (
            <p className="nav-link user_btn">
              <img
                className="avatar"
                src={user?.profileImage}
                alt="User profile"
                data-toggle="tooltip"
                data-placement="right"
              />
            </p>
          ) : null}
        </div> */}
      </div>
    </div>
  );
};

export default Navbar;

const styles = {
  logo: {
    height: "56px",
    width: "56px",
    borderRadius: "2rem",
    padding: "0.5rem",
    overflow: "hidden",
  },
};
