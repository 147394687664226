import React from "react";
import { Outlet } from "react-router-dom";

import Navbar from "components/Navbar";

const MainLayout = (props) => {
  return (
    <>
      {/* top navigation bar */}
      <Navbar profileImageUrl="https://firebasestorage.googleapis.com/v0/b/crewmen-d53cb.appspot.com/o/ProfileImages%2F5jTyJCENl9afu2Pnx99nWy0DsPQ2.jpg?alt=media&token=483c4fad-86cb-4381-8e6e-140a87194860" />

      <div className="container mt-3 mb-3">
        <Outlet />
      </div>
    </>
  );
};

export default MainLayout;
