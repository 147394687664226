import Swal from "sweetalert2";

export const alert = Swal.mixin({
  customClass: {
    confirmButton: "btn btn-primary",
    cancelButton: "btn btn-secondary",
  },
  confirmButtonColor: "#f68d2e",
  cancelButtonColor: "#B7B7B7",
});

export const errorAlert = (message) => {
  Swal.fire({
    title: "Error!",
    text: message,
    icon: "error",
    confirmButtonText: "OK",
    confirmButtonColor: "#f68d2e",
  });
};
